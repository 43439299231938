import React from 'react';
import * as S from './KlarnaPopup.styles';
import AddToCartButton from '../AddToCartButton/AddToCartButtonV1';

export const KlarnaPopup = props => {
  const { show, setShowKlarnaPopup, installments } = props;

  if (show) {
    return (
      <>
        <S.Container>
          <S.CloseButton onClick={() => setShowKlarnaPopup(false)}>
            &times;
          </S.CloseButton>
          <S.Logo src="/images/klarna.svg" />
          <S.Body>
            <h3>{installments} interest-free payments. No fees.</h3>
            <p>
              Pay in {installments} interest-free payments so you can spread the
              cost. No fees.
            </p>
            <ul>
              <li>Add item(s) to your cart</li>
              <li>
                Go to checkout and choose <span className="klarna-logo"></span>
              </li>
              <li>Enter your debit or credit card information.</li>
              <li>
                To check your eligibility, Klarna will perform a soft search
                with a credit reference agency. This will not affect your credit
                score.
              </li>
              <li>
                Spread the cost in {installments} interest-free payments. Your
                first payment is taken when the order is processed and the
                remaining {installments - 1} are automatically taken every two
                weeks. No fees.
              </li>
            </ul>
            <AddToCartButton
              buttonText="Add To Cart"
              backgroundColor="#001C72"
              pageDesign="design1"
            />
            <p className="gray">
              You must be 18+ to use this credit offer. Klarna uses soft
              searches with credit reference agencies. These do not affect your
              credit rating. Missed payments can affect your ability to use
              Klarna. Pay in {installments} instalments complete{' '}
              <a
                href="https://www.klarna.com/us/terms-of-use/"
                target="_blank"
                rel="noreferrer"
              >
                terms
              </a>
              .
            </p>
            <p className="gray text-center">
              <a
                href="https://www.klarna.com/us/"
                target="_blank"
                rel="noreferrer"
              >
                What is Klarna
              </a>
              |{' '}
              <a
                href="https://www.klarna.com/us/customer-service/"
                target="_blank"
                rel="noreferrer"
              >
                Klarna’s Customer Service
              </a>
            </p>
          </S.Body>
        </S.Container>
        <S.Wrapper onClick={() => setShowKlarnaPopup(false)} />
      </>
    );
  } else {
    return ``;
  }
};

export default KlarnaPopup;
